<template>
  <div id="app">
    <img  :src="imageUrl" alt="watch" />
  </div>
</template>

<script>
import desktopImg from '@/assets/desktop.jpg';
import mobileImg from '@/assets/mobile.jpg';
export default {
  name: "App",
  data() {
    return {
      imageUrl: "",
    };
  },
  mounted() {
    if (window.innerWidth >= 768) {
      this.imageUrl = desktopImg;
    } else {
      this.imageUrl = mobileImg;
    }
  },
};
</script>

<style>
body {
  margin: 0;
  padding: 0;
}
#app {
  img {
    width: 100%;
    height: auto;
  }
}
</style>
