import Vue from "vue";
import App from "./App.vue";
import VueMeta from "vue-meta";

Vue.use(VueMeta);

Vue.config.productionTip = false;


const script = document.createElement('script')
script.async = true
script.src = `https://www.googletagmanager.com/gtag/js?id=G-NJ0KHDRX9E`
document.head.appendChild(script)

script.onload = () => {
  window.dataLayer = window.dataLayer || []
  function gtag() {
    window.dataLayer.push(arguments)
  }
  gtag('js', new Date())
  gtag('config', 'G-NJ0KHDRX9E')
}



new Vue({
  render: (h) => h(App),
}).$mount("#app");
